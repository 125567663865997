import React, { useState } from "react"
import { useFormik } from "formik"
import * as Yup from "yup"

const ContactForm = () => {
  const [status, setStatus] = useState("")

  const formik = useFormik({
    initialValues: {
      fullName: '',
      phone: '',
      email: '',
      message: '',
    },
    validationSchema: Yup.object({
      fullName: Yup.string()
        .required('Required'),
      phone: Yup.string(),
      email: Yup.string()
        .email('Invalid email address')
        .required('Required'),
      message: Yup.string()
        .required('Required'),
    }),
    onSubmit: (values, { resetForm }) => {
      if (values.message.search("http") === -1) {
        if (typeof window !== "undefined" && window.gtag) {
          window.gtag('event', 'conversion', { 
            'send_to': 'AW-667741527/Eo2lCIjbz8MBENfas74C', 
            'value': 1.0, 
            'currency': 'USD' 
          })
        }
        submitForm(convertToFormData(values), resetForm)
      } else {
        resetForm()
      }
    }
  })

  const convertToFormData = (formObj) => {
    const formData = new FormData();
    Object.keys(formObj).map((key) => {
      formData.append(key, formObj[key] !== undefined ? formObj[key] : '')
      return
    })
    return formData
  }

  const submitForm = (formData, resetForm) => {
    const xhr = new XMLHttpRequest();
    xhr.open("post", "https://formspree.io/xayqrzrv");
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        setStatus("SUCCESS");
      } else {
        setStatus("ERROR");
      }
    };
    xhr.send(formData);
    resetForm()
    /*typeof window !== "undefined" &&
      window.gtag('event', 'form_submit', { send_to: "UA-154763728-1" });
    typeof window !== "undefined" &&
      window.gtag('event', 'conversion', { 'send_to': 'AW-667741527/Eo2lCIjbz8MBENfas74C' });*/
  }

  const inputClass = "shadow appearance-none border rounded w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
  const labelClass = "block text-gray-900 text-md font-bold mb-2"

  return (
    <>
      <form 
        style={{ marginBottom: 0 }} 
        action="https://formspree.io/xayqrzrv" 
        method="post" 
        onSubmit={formik.handleSubmit}
        className=" md:px-12 pt-6 pb-8 mb-4"
      >
        <div>
          <div>
            <label 
              className={labelClass} 
              htmlFor="fullName" 
              for="fullName"
            >
              Name*
            </label>
            <input 
                className={inputClass} 
                name="fullName" 
                {...formik.getFieldProps('fullName')} 
              />
            {formik.touched.fullName && formik.errors.fullName ? (
              <div className="text-red-600 text-sm mb-3">
                {formik.errors.fullName}
              </div>
            ) : <div className="mb-4"></div>}
          </div>
          <div>
            <label 
              className={labelClass} 
              htmlFor="phone" 
              for="phone"
            >
              Phone
            </label>
            <input 
                className={inputClass} 
                name="phone" 
                {...formik.getFieldProps('phone')} 
            />
          <div style={{ marginTop: 15 }}></div>
          </div>
          <div>
            <label 
              className={labelClass} 
              htmlFor="email" 
              for="email"
            >
              Email address*
            </label>
            <input 
                className={inputClass} 
                name="email" 
                {...formik.getFieldProps('email')} 
              />
            {formik.touched.email && formik.errors.email ? (
              <div 
                className="text-red-600 text-sm mb-3" 
              >
                {formik.errors.email}
              </div>
            ) : <div className="mb-4"></div>}
          </div>
        </div>
        <div style={{ paddingBottom: 15 }}>
          <label 
            className={labelClass} 
            htmlFor="message" 
            for="message"
          >
            Message*
          </label>
          <textarea 
              style={{minHeight: 150}} 
              className={inputClass} 
              name="message" 
              {...formik.getFieldProps('message')} 
            />
          {formik.touched.message && formik.errors.message ? (
            <div 
              className="text-red-600 text-sm mb-3" 
            >
              {formik.errors.message}
            </div>
          ) : <div className="mb-3"></div>}
        </div>
        <div style={{ width: '100%' }}>
          {status === "SUCCESS" ? 
          <p>Thanks!</p> : 
          <input 
            className="px-5 py-3 rounded-lg shadow-lg 
            uppercase tracking-wider font-semibold text-sm w-full
            focus:outline-none focus:shadow-outline bg-blue-500 text-white hover:bg-blue-400 active:bg-blue-600 hover:cursor-pointer" 
            type="submit" 
            value="SUBMIT"
          ></input>}
          {status === "ERROR" && <p>Oops! There was an error.</p>}
        </div>
      </form>
    </>
  )
}

export default ContactForm
